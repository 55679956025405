import React, { useState, useEffect } from "react"
//import elsaMainImg from "../img/elsaMain.png"
import elsa01 from "../img/elsa_0.png"
//import ElsaPic1 from "../img/elsa_gateway_r.png"
import ElsaPic1 from "../components/elsa/elsaPic1"
import ElsaPic2 from "../components/elsa/elsaPic2"
import ElsaPic3 from "../components/elsa/elsaPic3"
import CoverflowEffect from "../components/elsa/CoverflowEffect"
import WhereTo from "../components/elsa/whereTo"
import Layout from "../components/layoutEn"
import SEO from "../components/layout/seo"
//import elsaPc from "../img/elsa_pc.png"
//import elsaMobile from "../img/elsa_mobile.png"
import achieveIcon_0 from "../img/icons/achieveIcon_0.png"
import achieveIcon_1 from "../img/icons/achieveIcon_1.png"
import achieveIcon_2 from "../img/icons/achieveIcon_2.png"
import achieveIcon_3 from "../img/icons/achieveIcon_3.png"

import classNames from "classnames"

function useScroll(position) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > position)
    }
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [position])

  return scrolled
}

function Elsa() {
  const scrolled = useScroll(1700)
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div
        className={classNames(
          "elsaWrap backColor",
          scrolled && "backColorAnimated"
        )}
      >
        <div className="sectionWrap elsaOverview">
          <p className="elsaMainImg">{/*<img src={elsaMainImg} alt="" />*/}</p>
          <div className="textWrap">
            <h2>
              ELSA
              <span>Elevator Smart Advisor</span>
            </h2>
            <div className="bodyText">
              <p>
                Intelligent IoT connecting local sites and management centers
              </p>
              <strong>
                ELSA stands for Evator Smart Advisor and stands also for
                "Everything's Linked, Smart and Safe to All."
              </strong>
              <br />
              M2MTech's ELSA solution, which can be applied to all industries
              such as elevators, escalators, smart logistics, and parking
              towers, communicates and secures each node through an intelligent
              IoT gateway that connects the local field and the cloud center. We
              provide a high-quality and secure IoT service environment that
              applies the latest international IoT standards and web security
              standards. In addition, because of the flexible server operation
              and billing as much as the use of the cloud system, you can use
              the Elsa solution by building a server at a low price at a large
              site as well as at a small site.
            </div>
          </div>
        </div>
        <div className="sectionWrap elsaDetail">
          <div className="textWrap">
            <h2>Smart remote care predictive maintenance system</h2>
            <p className="bodyText">
              The ELSA solution collects and analyzes data from elevator key
              safety components and sensors in real time to provide users with
              intelligent services that can prevent accidents and predict
              failures. It provides a safe IoT service environment by applying
              the latest international security standards such as IoT-based
              smart remote care system, IoT device, server, inter-node
              communication, and web security.
            </p>
          </div>
          <div className="elsaBenefit">
            <ul>
              <li>
                <p className="kind">Recording and Management</p>
                <p className="kindDetail">
                  Automatically record, view, filter, and retrieve easy devices,
                  on-site additional deletion, device trip logs, breakdowns and
                  errors.
                </p>
              </li>
              <li>
                <p className="kind">
                  Fast and accurate fault detection and response
                </p>
                <p className="kindDetail">
                  In the event of a breakdown or an emergency, we notify the
                  central situation room, each center, and engineer to PC and
                  mobile devices immediately. Immediate response is possible
                  even on the move.
                </p>
              </li>
              <li>
                <p className="kind">Artificial Intelligence</p>
                <p className="kindDetail">
                  After collecting video, vibration, and sound information,
                  predict the emergency situation through deep learning and
                  recommend a manual or necessary/usable parts in case of an
                  emergency.
                </p>
              </li>
              <li>
                <p className="kind">monitoring</p>
                <p className="kindDetail">
                  Predictive management of equipment aging such as sites and
                  elevators. It informs you that it is possible to manage parts
                  without checking the inventory status at any time.
                </p>
              </li>
              <li>
                <p className="kind">Remote control/diagnosis</p>
                <p className="kindDetail">
                  The device can be remotely controlled to schedule test runs
                  and daily system checks. In the event of a failure, it can be
                  operated and resolved in the situation room without having to
                  be dispatched.
                </p>
              </li>
              <li>
                <p className="kind">Provide statistics and reports</p>
                <p className="kindDetail">
                  It provides statistics on the operation and work results of
                  the device in tables and graphs, and automatically generates
                  reports and enables you to use them freely.
                </p>
              </li>
              <li>
                <p className="kind">Save money at a reasonable cost</p>
                <p className="kindDetail">
                  Depending on the size of the site, you can enjoy high cost
                  savings by selecting and using the service.
                </p>
              </li>
              <li>
                <p className="kind">Engineer management</p>
                <p className="kindDetail">
                  The available personnel of the engineer are managed, the
                  location and situation are reported in real time, and
                  reporting is automated after the task or situation is handled,
                  enabling efficient and fast work.
                </p>
              </li>
              {/*          <li>
            <p className="kind">리코딩 &amp; 리플레이</p>
            <p className="kindDetail">영상</p>
</li>*/}
            </ul>
          </div>
          <div
            className={classNames("elsa01Img", scrolled && "elsa01ImgAnimated")}
          >
            <img src={elsa01} alt="" />
          </div>
          <div className="elsaAchieve">
            <div className="textWrap">
              <h2>Certification of innovation and originality in technology</h2>
              <p className="bodyText">
                It complies with international standards such as IoT-based smart
                remote care system, IoT device, server, inter-node
                communication, and web security, applies the latest
                international security standards, and provides safe and fast
                Internet of Things service environment only through original
                technology development.
              </p>
            </div>
            <ul>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_0} alt="" />
                </p>
                <p>
                  The first to CoAP Interoperability TTA Verified (Certification
                  No. TTA-V-N-17-020) Certified in Korea.
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_1} alt="" />
                </p>
                <p>
                  Obtained a patent for autonomous guidance system and
                  method/implementation on the basis of elevator IoT in 2019.
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_2} alt="" />
                </p>
                <p>
                  World-class web security evaluation(Mozilla Observatory) Grade
                  A+ (Top 0.19%).
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_3} alt="" />
                </p>
                <p>
                  Applied CoAP IoT Stack with 4.5x transmission throughput
                  performance per second.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="sectionWrap elsaServices">
          <div className="backgroundAnimation elsaBackgroundAnimation">
            <p></p>
          </div>
          <div className="textWrapMain">
            <h2>ELSA Service</h2>
          </div>
          <ul>
            <li className="elsaServices0">
              <div className="elsaServicesImg elsaServicesImg0">
                <ElsaPic2 />
              </div>
              <div className="textWrap">
                <h2>
                  ELSA IoT Gateway<span className="tmText">TM</span>
                </h2>
                <p className="serviceKind">IT and OT Data</p>
                <p className="bodyText">
                  <span>Data collection equipment</span>
                  ELSA IoT Gateway<span className="tmText">TM</span>is a
                  manufacturer/vendor independent intelligent IoT device that
                  connects core devices, sensors, and mobile devices of various
                  facilities in the field to the cloud center.
                </p>
                <ol>
                  <li>
                    <span>Connectivity</span>
                    <span>– Easy connection to data sources by IoT Stack</span>
                  </li>
                  <li>
                    <span>Security / stability</span>
                    <span>– Data encryption</span>
                    <span>– Security experts</span>
                  </li>
                  <li>
                    <span>Scalability</span>
                    <span>– Bridge / Ext. for Legacy connection function</span>
                    <span>– Based on open platform</span>
                    <span>– Compliance with international IoT standards</span>
                  </li>
                </ol>
              </div>
            </li>
            <li className="elsaServices1">
              <div className="elsaServicesImg elsaServicesImg1">
                <ElsaPic1 />
              </div>
              <div className="textWrap">
                <h2>
                  ELSA.Edge<span className="tmText">TM</span>
                </h2>
                <p className="serviceKind">Secure edge-to-cloud processing</p>
                <p className="bodyText">
                  <span>
                    Fast and reasonable big data analysis processing, cloud
                    service
                  </span>
                  ELSA.Edge<span className="tmText">TM</span>is a technology
                  that receives and analyzes big data under strong security
                  without forgery and falsification using edge computing. It is
                  an integrated and open IoT platform designed to transmit data
                  at high speed so that you can use the service on any mobile
                  device anytime, anywhere.
                </p>
                <ol>
                  <li>
                    <span>IoT/AI framework</span>
                    <span>– Data collection, analysis and AI learning</span>
                    <span>
                      – Protocol adapter, data bus, modern web/app service
                      container-based framework
                    </span>
                    <span>
                      – Node authentication, authority management, SW
                      distribution function
                    </span>
                  </li>
                  <li>
                    <span>flexibility</span>
                    <span>– Billing according to usage</span>
                    <span>– Server expansion in a short time</span>
                  </li>
                  <li>
                    <span>convenience</span>
                    <span>– Anytime, anywhere access via the Internet</span>
                    <span>– Secure Browse-based data access</span>
                    <span>– Private Operations Center by PaaS Platform</span>
                  </li>
                </ol>
              </div>
            </li>
            <li className="elsaServices2">
              <div className="elsaServicesImg elsaServicesImg2">
                <ElsaPic3 />
              </div>
              <div className="textWrap">
                <h2>
                  ELSA.Cloud SaaS<span className="tmText">TM</span>
                </h2>
                <p className="serviceKind">Industrial App</p>
                <p className="bodyText">
                  <span>
                    Equipment Health Check and Workflow Monitoring, Statistical
                    Reporting + ERP Platform
                  </span>
                  ELSA.Cloud SaaS<span className="tmText">TM</span>is a
                  responsive web/app service that can be accessed from any
                  device with wired/wireless internet access, providing
                  real-time status check and remote response workflow
                  monitoring, manpower management, statistical reporting, and
                  UX/UI at a glance.
                  <br />
                  <br />
                  With the flexible server operation and charging structure, you
                  can use the service at the best price at both small and large
                  sites.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="sectionWrap where">
          <div className="textWrap">
            <h2>Applicable Field</h2>
            <p className="bodyText">
              As a solution that can be used in most industries, it serves as a
              channel for communication between machines and people, and provide
              the business efficiency and cost savings that are essential to
              business operations.
            </p>
          </div>
          <WhereTo />
        </div>
        <div className="sectionWrap elsaCloud">
          <div className="textWrap">
            <h2>User-optimized ELSA.Cloud Platform</h2>
            <p className="bodyText">
              It provides clear statistical analysis and error call reception
              management screens on various devices such as desktop and pad
              mobile. Easily add/manage sites and elevators, and customize
              various settings to provide engineers with a more comfortable
              working environment and reduce costs and time for companies.
            </p>
          </div>
          <div className="elsaCloudGallery">
            <CoverflowEffect />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Elsa
